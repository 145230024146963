.card-person-container {
}

#background-img {
  position: absolute;
  width: 100%;
}

#card-img {
  width: 100%;
  border-radius: 0;
  border: none;
}

.card-name {
  padding: 1.5rem;
}

.card-bio {
  padding: 1.5rem;
  padding-top: 0;
}
