a,
a:link,
a:visited {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
  display: inline-block;
  line-height: 1.2;
  transition: 0.3s;
  position: relative;
}

#scan-button {
  width: 50%;
  height: 30px;
  text-align: center;
  background-color: #febf00;
}

a.link {
  border-bottom: 1px solid var(--black);
}

a:link:hover {
  transition: 0.3s;
  cursor: pointer;
}

/* fonts */
h1,
h2 {
  font-family: FoundersGrotesk-Web, sans-serif;
  font-weight: var(--medium);
  font-style: normal;
  font-size: 2.4rem;
  line-height: 1.1em;
  margin: 0;
  letter-spacing: -0.02em;
}

h2 {
  font-size: 1.6rem;
}

.highlighted {
  color: var(--yellow);
}

.comic-sans {
  font-family: "Comic Sans MS", "Comic Sans", "sans-serif";
}

p,
::placeholder {
  font-family: aktiv-grotesk, sans-serif;
  line-height: 1.6em;
  font-size: 0.95rem;
  margin: 0;
  padding: 0;
}

p.subhead {
  color: var(--yellow);
}

.bold {
  font-weight: var(--bold);
}

/* general */

body {
  background-color: var(--white);
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 0;
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  padding: 2rem;
  display: grid;
  grid-auto-columns: auto;
  grid-gap: 2rem;
  width: 100%;
}

@media screen and (min-width: 460px) {
  .container {
    width: 400px;
    margin: 4rem auto;
    box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
  }
}

.centered {
  text-align: center;
}

/* button */
.button {
  border: 3px solid var(--black);
  color: var(--black);
  padding: 0rem 2rem;
  margin: 10px 0;
  border-radius: 1rem;
  cursor: pointer;
  width: fit-content;
}

.button:hover {
  background-color: var(--transparent);
  color: var(--white);
}

/* header */
header {
  padding: 2rem 0;
}

/* cards */
#cards {
  display: grid;
  grid-gap: 1rem;
}

.card {
  background-color: var(--black);
  color: var(--white);
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.card.inverted {
  background-color: var(--white);
  color: var(--black);
  border: 3px solid var(--black);
}

/* toggle card on click */
.dropdown {
  overflow: hidden;
  max-height: 0px;
  transition: max-height var(--short);
  display: grid;
  grid-gap: 1rem;
}

.card.clicked .dropdown {
  max-height: 400px;
}

/* add spacing above dropdown */
.dropdown::before {
  content: "";
  display: block;
  margin-top: 0.5rem;
}

/* dropdown arrow */
.dropdown-arrow {
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-arrow::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--black);
  border-radius: 0.4rem;
  transition: var(--short) ease-out;
}

/* dropdown inverted styling */
.card.inverted .dropdown-arrow {
  background-color: var(--black);
}

.card.inverted .dropdown-arrow::after {
  border-top-color: var(--white);
}

/* dropdown arrow animations */
.card.clicked .dropdown-arrow::after {
  transform: rotate(180deg);
}

/* card styling */
.card-container {
  padding: 0 1rem 1rem 1rem;
}

.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-icon {
  width: 80%;
  display: flex;
  margin: 0 auto;
  padding: 0.5rem 0 1rem 0;
}

/* cohort */
.profiles-layout {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-name {
}

.headshot {
  width: 60px;
  border-radius: 9999rem;
  border: 3px solid var(--white);
}

.back-button {
  position: fixed;
  height: 24px;
  width: 24px;
  margin: 1rem;
  background-color: #fff;
  border-color: #fff0 !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button::after,
.back-button::before {
  content: "";
  display: block;
  position: absolute;
  height: 12px;
  width: 2px;
  background-color: var(--black);
  border-radius: 2px;
  transform: rotate(45deg);
}

.back-button::after {
  transform: rotate(-45deg);
}
