.card.clicked .dropdown {
  max-height: 100%;
}

#scanner {
  display: grid;
  justify-self: center;
  border-radius: 1rem;
  overflow: hidden;
}

#scan-modal {
  opacity: 1;
  padding: 0 !important;
  border: 3px solid var(--black) !important;
  border-radius: 1rem !important;
}

@media screen and (min-width: 460px) {
  #scan-modal {
    max-width: 300px;
    margin: 0 auto;
  }
}

.cohort {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.personSection:nth-of-type(3n) {
  grid-column: span 2;
}

.profiles-layout {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.personSection {
  justify-self: center;
}
